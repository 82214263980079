
  import userController from '/home/builduser/work/6dca45ae3e6ec396/packages/form-example/src/components/StatelessForm/controller.ts';
  import createControllerWrapper from '@wix/yoshi-flow-editor/runtime/esm/pureControllerWrapper.js';

  
import wrapController from '@wix/yoshi-flow-editor-runtime/internal/viewerScript/ooi';


  
var createHttpClient = null;

  

  import { initI18n as initI18n } from '@wix/yoshi-flow-editor/runtime/esm/i18next/init';


  
const multilingualDisabled = false;

  

    var createExperiments = null;
    var createWidgetExperiments = null;
    


  var sentryConfig = {
    DSN: 'https://1f948ce411734c10b70ac012f7560057@sentry.wixpress.com/3604',
    id: '1f948ce411734c10b70ac012f7560057',
    projectName: 'form-example',
    teamName: 'form-example',
    errorMonitor: true,
  };

  var experimentsConfig = {"centralized":true,"scopes":[]};

  var translationsConfig = {"icuEnabled":true,"defaultTranslationsPath":"/home/builduser/work/6dca45ae3e6ec396/packages/form-example/src/assets/locales/messages_en.json","availableLanguages":["en","uk"]};

  var biConfig = null;

  var defaultTranslations = {"app.widget.welcome":"Welcome","app.settings.label":"Configure Widget","app.settings.tabs.main":"Main","app.settings.tabs.design":"Design","app.settings.defaults.greetingsPretext":"to"};

  var fedopsConfig = null;

  import { createVisitorBILogger as biLogger } from '/home/builduser/work/6dca45ae3e6ec396/packages/form-example/target/generated/bi/createBILogger.ts';

  const controllerOptions = {
    sentryConfig,
    biConfig,
    fedopsConfig,
    experimentsConfig,
    biLogger,
    translationsConfig,
    persistentAcrossPages: false,
    appName: "Form Example",
    componentName: "StatelessForm",
    appDefinitionId: "813e0fc5-89be-48a2-89c0-5fd74d4beebd",
    componentId: "30935fd4-3355-4939-92a4-7d9dd5c8ffd1",
    projectName: "form-example",
    defaultTranslations,
    multilingualDisabled,
    shouldUseEssentials: true,
    withErrorBoundary: false,
    localeDistPath: "assets/locales"
  };

  const _controller = createControllerWrapper(userController, controllerOptions, {
    initI18n,
    createHttpClient,
    createExperiments,
  });

  export const wrap = wrapController;
  export const descriptor = {
    ...controllerOptions,
    id: controllerOptions.componentId,
    widgetType: "WIDGET_OUT_OF_IFRAME",
    controllerFileName: "/home/builduser/work/6dca45ae3e6ec396/packages/form-example/src/components/StatelessForm/controller.ts",
  };

  export const controller = _controller
  export default controller;
