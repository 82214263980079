import { initFormController } from '@wix/form-viewer/controller';
import {
  type ControllerFlowAPI,
  type CreateControllerFn,
} from '@wix/yoshi-flow-editor';
import { getFormId, getFormIdFromUrl } from '../../services/get-form-id';
import {
  type ActionResultsById,
  type ControllerProps,
  type SubmitForm,
} from './types';
import { createSubmission } from '../../services/submission-service';

const createController: CreateControllerFn = async ({
  flowAPI,
  controllerConfig,
}) => {
  const { setProps } = controllerConfig;
  return {
    async pageReady() {
      const formId =
        getFormIdFromUrl(flowAPI) || (await getFormId(flowAPI.httpClient));
      const actionResultsById: ActionResultsById = {};

      try {
        await initFormController(flowAPI, {
          formId,
          namespace: 'wix.form_example.form',
        });
      } catch {}

      const controllerProps: ControllerProps = {
        formId,
        submitForm: createSubmitForm(flowAPI, actionResultsById),
        actionResultsById,
      };

      setProps(controllerProps);
    },
  };
};

function createSubmitForm(
  flowAPI: ControllerFlowAPI,
  actionResultsById: ActionResultsById,
): SubmitForm {
  return async (actionId, formId, values) => {
    const result = await createSubmission(
      flowAPI.essentials.httpClient,
      formId,
      values,
    );

    actionResultsById[actionId] = result;
    flowAPI.controllerConfig.setProps({
      actionResultsById,
    });
  };
}

export default createController;
