import {
  type IHttpClient,
  type ControllerFlowAPI,
} from '@wix/yoshi-flow-editor';

export async function getFormId(httpClient: IHttpClient): Promise<string> {
  const result: any = await httpClient.get(
    `/_api/app-settings-service/v1/settings/instances`,
  );
  return result?.data?.settings?.formId;
}

export function getFormIdFromUrl(flowAPI: ControllerFlowAPI) {
  if (!flowAPI?.controllerConfig?.wixCodeApi?.location?.url) {
    return;
  }

  const url = new URL(flowAPI.controllerConfig.wixCodeApi.location.url);
  const params = new URLSearchParams(url.search);
  return params.get('formId');
}
